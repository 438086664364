<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="text-h4 primary--text">Applicant Search</h1>
        </v-col>
        <v-col cols="12">
          <ContactPropertySearch></ContactPropertySearch>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import ContactPropertySearch from '@components/search/VwContactProperty'
export default {
  //model: {
  //	prop: ''
  //},
  props: {},
  components: {
    ContactPropertySearch,
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
  watch: {},
}
</script>
<style scoped></style>
