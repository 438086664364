var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseServerSyncDataTable',{attrs:{"headers":_vm.filteredHeaders,"items":_vm.vwContactProperties,"searchText":_vm.searchText,"search":true,"itemKey":"PropertyId","loading":_vm.loading,"searchTotal":_vm.searchTotal,"searchSortBy":_vm.searchSortBy,"searchDescending":_vm.searchDescending,"searchPage":_vm.searchPage,"searchRowsPerPage":_vm.searchRowsPerPage,"expanded":_vm.expanded,"single-expand":"","show-expand":""},on:{"update:searchSortBy":function($event){_vm.searchSortBy=$event},"update:search-sort-by":function($event){_vm.searchSortBy=$event},"update:searchDescending":function($event){_vm.searchDescending=$event},"update:search-descending":function($event){_vm.searchDescending=$event},"update:searchPage":function($event){_vm.searchPage=$event},"update:search-page":function($event){_vm.searchPage=$event},"update:searchRowsPerPage":function($event){_vm.searchRowsPerPage=$event},"update:search-rows-per-page":function($event){_vm.searchRowsPerPage=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"searchParams",fn:function(){return [_c('VwContactPropertyParams')]},proxy:true},{key:"item.links",fn:function({ item }){return [_c('span',[(item.ContactId)?_c('BaseTooltipButton',{attrs:{"small":"","iconColor":"primary","icon":"mdi-account"},on:{"click":function($event){return _vm.$router.push({
            name: 'staffForm',
            query: { contactId: item.ContactId },
          })}}},[_vm._v("Go To Contact ")]):_vm._e(),(item.PropertyId)?_c('BaseTooltipButton',{attrs:{"small":"","iconColor":"primary","icon":"mdi-home"},on:{"click":function($event){return _vm.$router.push({
            name: 'staffForm',
            query: { propertyId: item.PropertyId },
          })}}},[_vm._v("Go To Taxable Property ")]):_vm._e()],1)]}},{key:"expanded-item",fn:function({ item, headers }){return [_c('td',{staticClass:"pa-2",attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.FirstName)+" "+_vm._s(item.LastName)+" ")]),(_vm.showSSN)?_c('div',{staticClass:"pointer d-inline",on:{"click":function($event){_vm.showSSN = false}}},[_vm._v(" "+_vm._s(item.Ssn)+" ")]):_c('div',{staticClass:"pointer primary--text d-inline",on:{"click":function($event){_vm.showSSN = true}}},[_vm._v("- show SSN -")]),(item.ParcelId)?_c('div',[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Parcel")]),_vm._v(" "+_vm._s(item.ParcelId)+" ")]):_vm._e(),(item.MostRecentPropertyPeriod)?_c('div',[(item.MostRecentPropertyPeriod)?_c('span',{staticClass:"font-weight-medium"},[_vm._v("Most Recent Period ")]):_vm._e(),_vm._v(" "+_vm._s(item.MostRecentPropertyPeriod)+" ")]):_vm._e()]),_c('v-col',{staticClass:"text-left",attrs:{"cols":"12","md":"2"}},[_c('span',{staticClass:"font-weight-medium d-block"},[_vm._v("Property Address ")]),_c('div',[_vm._v(" "+_vm._s(item.StreetAddress1)+" ")]),(item.StreetAddress2)?_c('div',[_vm._v(" "+_vm._s(item.StreetAddress2)+" ")]):_vm._e(),_c('div',[_vm._v(" "+_vm._s(item.City)+", "+_vm._s(item.StateAbr)+" "+_vm._s(item.Zip)+" ")])]),_c('v-col',{staticClass:"text-left",attrs:{"cols":"12","md":"2"}},[(item.Phone)?_c('div',[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Phone")]),_vm._v(" "+_vm._s(item.Phone)+" ")]):_vm._e(),(item.Email)?_c('div',[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Email ")]),_c('a',{attrs:{"href":`mailto:${item.Email}`}},[_vm._v(" "+_vm._s(item.Email)+" ")])]):_vm._e()])],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }