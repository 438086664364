<template>
  <div>
    <v-row justify="end" class="mb-4">
      <v-col sm="12" md="3"
        ><LuCounty
          clearable
          :items="filteredCounties"
          v-model="searchCountyId"
        ></LuCounty
      ></v-col>
      <v-col sm="12" md="3"
        ><LuCity
          clearable
          :items="filteredCities"
          v-model="searchCityId"
        ></LuCity
      ></v-col>
      <v-col class="ml-auto" sm="12" md="2">
        <v-text-field
          v-model="searchText"
          append-icon="mdi-magnify"
          label="Search"
          clearable
          single-line
          hide-details="auto"
          hint="FirstName, LastName, Email, Ssn, Phone, Parcel, StreetAddress1, StreetAddress2, Zip"
        ></v-text-field>
      </v-col>
      <v-col cols="2" class="pt-6">
        <BaseTooltipButton
          iconColor="primary"
          icon="mdi-account-plus"
          buttonRightText="Add New Application"
          :iconButton="false"
          @click="
            $router.push({
              name: 'staffForm',
            })
          "
          >Add New Application
        </BaseTooltipButton>
      </v-col>
    </v-row>

    <!-- //templateTodo: if you use a custom search dto uncomment this to allow showing of filter params-->
    <!-- <v-row justify="center">
          <v-col sm="12" md="2">
              <h4 @click="toggleFilters" class="pointer primary--text">{{filterText}}
              </h4>
          </v-col>
      </v-row> -->
    <v-row v-if="filters">
      <!-- The Description and ID (item-text and item-value) 
          will need to be adjusted based on the properties in the array/lookup table
          these availableLists should be pulled from lookup tables and potentially
          filtered with a computed as desired (for IsActive status etc)
          These can also be switched to v-autocompletes if desired-->

      <!-- <v-col cols="12" sm="auto">
        <v-switch v-model="searchShowInactive" label="Show Inactive"></v-switch>
        </v-col> -->
      <!-- <v-col cols="12" sm="auto">
              <v-select 
                  v-model="searchPropertyIsActive" 
                  :items="['true', 'false']"
                  label="Property Is Active"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
    </v-row>
  </div>
</template>

<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuCounty from '@components/select/LuCounty/LuCounty-a-multi-id'
import LuCity from '@components/select/LuCity/LuCity-a-multi-id'
export default {
  page: {
    title: 'Contact Search',
  },
  name: 'VwContactPropertySearchParams',
  data: () => ({
    filters: false,
    filterText: '-Hide Filters-',
  }),
  components: {
    LuCounty,
    LuCity,
  },
  created() {
    this.loadLuCounties()
    this.loadLuCities()
  },
  watch: {},
  computed: {
    ...sync('vwContactProperty', [
      'searchText',
      'searchCountyId',
      'searchCityId',
      //templateTODO: uncomment as used in VwContactPropertySearchParams
      // 'searchShowInactive',
      // 'searchPropertyIsActive',
    ]),
    ...get('luCounty', ['luCounties']),
    ...get('luCity', ['luCities']),
    //templateTODO: custom available lookup computed properties should go here
    filteredCounties() {
      if (
        this.searchCityId.length > 0 &&
        this.luCities &&
        this.luCities.length > 0
      ) {
        var filteredCounties = []
        this.luCities.forEach((x) => {
          if (this.searchCityId.includes(x.Id)) {
            x.Counties.forEach((y) => {
              if (!filteredCounties.find((i) => i.Id == y.Id)) {
                filteredCounties.push(y)
              }
            })
          }
        })
        return filteredCounties
      } else {
        return null
      }
    },
    filteredCities() {
      if (
        this.searchCountyId.length > 0 &&
        this.luCounties &&
        this.luCounties.length > 0
      ) {
        var filteredCities = []
        this.luCounties.forEach((x) => {
          if (this.searchCountyId.includes(x.Id)) {
            x.Cities.forEach((y) => {
              if (!filteredCities.find((i) => i.Id == y.Id)) {
                filteredCities.push(y)
              }
            })
          }
        })
        return filteredCities
      } else {
        return null
      }
    },
  },
  methods: {
    loadLuCounties: call('luCounty/loadLuCounties'),
    loadLuCities: call('luCity/loadLuCities'),
    toggleFilters() {
      this.filters = !this.filters
      if (this.filters) {
        this.filterText = '-Hide Filters-'
      } else {
        this.filterText = '-Show Filters-'
      }
    },
  },
}
</script>

<style lang="scss"></style>
