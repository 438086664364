<template>
  <BaseServerSyncDataTable
    :headers="filteredHeaders"
    :items="vwContactProperties"
    :searchText="searchText"
    :search="true"
    itemKey="PropertyId"
    :loading="loading"
    :searchTotal="searchTotal"
    :searchSortBy.sync="searchSortBy"
    :searchDescending.sync="searchDescending"
    :searchPage.sync="searchPage"
    :searchRowsPerPage.sync="searchRowsPerPage"
    :expanded.sync="expanded"
    single-expand
    show-expand
  >
    <!-- //templateTodo: uncomment use custom search params-->
    <template v-slot:searchParams>
      <VwContactPropertyParams></VwContactPropertyParams>
    </template>
    <template v-slot:item.links="{ item }">
      <span>
        <BaseTooltipButton
          v-if="item.ContactId"
          small
          iconColor="primary"
          icon="mdi-account"
          @click="
            $router.push({
              name: 'staffForm',
              query: { contactId: item.ContactId },
            })
          "
          >Go To Contact
        </BaseTooltipButton>
        <BaseTooltipButton
          v-if="item.PropertyId"
          small
          iconColor="primary"
          icon="mdi-home"
          @click="
            $router.push({
              name: 'staffForm',
              query: { propertyId: item.PropertyId },
            })
          "
          >Go To Taxable Property
        </BaseTooltipButton>
      </span>
    </template>
    <template v-slot:expanded-item="{ item, headers }">
      <td :colspan="headers.length" class="pa-2">
        <v-row justify="start">
          <v-col cols="12" md="2">
            <span class="font-weight-medium"
              >{{ item.FirstName }} {{ item.LastName }}
            </span>
            <div
              class="pointer d-inline"
              v-if="showSSN"
              @click="showSSN = false"
            >
              {{ item.Ssn }}
            </div>
            <div
              class="pointer primary--text d-inline"
              v-else
              @click="showSSN = true"
              >- show SSN -</div
            >

            <div v-if="item.ParcelId">
              <span class="font-weight-medium">Parcel</span> {{ item.ParcelId }}
            </div>
            <div v-if="item.MostRecentPropertyPeriod">
              <span
                class="font-weight-medium"
                v-if="item.MostRecentPropertyPeriod"
                >Most Recent Period
              </span>
              {{ item.MostRecentPropertyPeriod }}
            </div>
          </v-col>
          <v-col cols="12" md="2" class="text-left">
            <span class="font-weight-medium d-block">Property Address </span>
            <div>
              {{ item.StreetAddress1 }}
            </div>
            <div v-if="item.StreetAddress2">
              {{ item.StreetAddress2 }}
            </div>
            <div> {{ item.City }}, {{ item.StateAbr }} {{ item.Zip }} </div>
          </v-col>
          <v-col cols="12" md="2" class="text-left">
            <div v-if="item.Phone">
              <span class="font-weight-medium">Phone</span> {{ item.Phone }}
            </div>
            <div v-if="item.Email">
              <span class="font-weight-medium">Email </span
              ><a :href="`mailto:${item.Email}`">
                {{ item.Email }}
              </a>
            </div>
          </v-col>
        </v-row>
      </td>
    </template>
  </BaseServerSyncDataTable>
</template>

<script>
import { get, sync, commit, call } from 'vuex-pathify'
//templateTodo: uncomment use custom search params
import VwContactPropertyParams from '@components/search/VwContactPropertyParams'

export default {
  name: 'VwContactPropertySearchTable',
  components: {
    //templateTodo: uncomment use custom search params
    VwContactPropertyParams,
  },
  data: () => ({
    showSSN: false,
    expanded: [],
  }),
  created() {
    this.searchVwContactProperties()
  },
  computed: {
    ...get('vwContactProperty', ['vwContactProperties', 'loading']),
    ...sync('vwContactProperty', [
      'searchTotal',
      'searchPage',
      'searchSortBy',
      'searchDescending',
      'searchRowsPerPage',
      'searchText',
    ]),
    headers() {
      return [
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'links',
          visible: true,
        },
        {
          text: 'Contact Id',
          align: 'center',
          sortable: true,
          value: 'ContactId',
          visible: false,
        },
        {
          text: 'First Name',
          align: 'center',
          sortable: true,
          value: 'FirstName',
          visible: true,
        },
        {
          text: 'Last Name',
          align: 'center',
          sortable: true,
          value: 'LastName',
          visible: true,
        },
        {
          text: 'Email',
          align: 'center',
          sortable: true,
          value: 'Email',
          visible: false,
        },
        {
          text: 'Ssn',
          align: 'center',
          sortable: true,
          value: 'Ssn',
          visible: false,
        },
        {
          text: 'Phone',
          align: 'center',
          sortable: true,
          value: 'Phone',
          visible: false,
        },
        {
          text: 'Property Id',
          align: 'center',
          sortable: true,
          value: 'PropertyId',
          visible: false,
        },
        {
          text: 'Parcel',
          align: 'center',
          sortable: true,
          value: 'ParcelId',
          visible: true,
        },
        {
          text: 'County Id',
          align: 'center',
          sortable: true,
          value: 'CountyId',
          visible: false,
        },
        {
          text: 'County',
          align: 'center',
          sortable: true,
          value: 'County',
          visible: true,
        },
        {
          text: 'Street Address 1',
          align: 'center',
          sortable: true,
          value: 'StreetAddress1',
          visible: true,
        },
        {
          text: 'Street Address 2',
          align: 'center',
          sortable: true,
          value: 'StreetAddress2',
          visible: false,
        },
        {
          text: 'City Id',
          align: 'center',
          sortable: true,
          value: 'CityId',
          visible: false,
        },
        {
          text: 'City',
          align: 'center',
          sortable: true,
          value: 'City',
          visible: true,
        },
        {
          text: 'State Id',
          align: 'center',
          sortable: true,
          value: 'StateId',
          visible: false,
        },
        {
          text: 'State Abr',
          align: 'center',
          sortable: true,
          value: 'StateAbr',
          visible: false,
        },
        {
          text: 'State',
          align: 'center',
          sortable: true,
          value: 'State',
          visible: false,
        },
        {
          text: 'Zip',
          align: 'center',
          sortable: true,
          value: 'Zip',
          visible: false,
        },
        {
          text: 'Property Is Active',
          align: 'center',
          sortable: true,
          value: 'PropertyIsActive',
          visible: false,
        },
        {
          text: 'Most Recent Period',
          align: 'center',
          sortable: true,
          value: 'MostRecentPropertyPeriod',
          visible: true,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    searchVwContactProperties: call(
      'vwContactProperty/searchVwContactProperties'
    ),
    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item]
    },
  },
}
</script>

<style></style>
